
































import { Vue, Component } from 'vue-property-decorator';
import FilterBarReports from '@/components/reports/FilterBarReports.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import BTablePonderador from '@/components/reports/BTablePonderador.vue';
import { ITablePonderador } from '@/model/reports/ITablePonderador';
import { internet } from '@/utils/Internet';
import { AxiosResponse } from 'axios';
import Util from '@/utils/Util';

@Component({
		name: 'MainRepPonderadores',
        components: {
			AppContainerBase,
      FilterBarReports,
      BTablePonderador }})
      
  export default class MainRepPonderadores extends MixinMain {
    public title = this.$t("MainRepPonderadores.title");
    public subtitle = this.$t("MainMotivoMora.subtitle");
    public isLoading = false;
    public data: Array<ITablePonderador> = [];
    
    mounted(): void {
      this.getData();
    }

    private getData() {
      this.isLoading = true;
      const request_1 = internet
        .newRequest()
        .get(`getEvaluacionesPonderadas?${this.getFilterUrl}`);
      Util.waitForPromises([request_1])
        .then((responses) => {
          this.updateData(responses[0] as AxiosResponse);
        })
        .catch(console.log)
        .finally(() => {
            this.isLoading = false;
        });
    }
    private updateData(response: AxiosResponse) {
      const data = [];
      for (let item of response.data.lista) {
        data.push(item)
      }
      this.data = data;
    }
  }
