

































import { Prop, Vue, Component } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { ILabels } from '@/model/labels/ILabels';
import { IBTable } from '@/model/reports/IBTable';
import { BootstrapVue, TablePlugin,	BTbody, BThead, BTr, BTd, BTh } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(TablePlugin)
Vue.component('b-tbody', BTbody)
Vue.component('b-thead', BThead)
Vue.component('b-tr', BTr)
Vue.component('b-td', BTd)
Vue.component('b-th', BTh)

 @Component({
		name: 'BTablePonderador',
        components: {
        }})
      
      export default class BTablePonderador extends MixinMain {
        public title = this.$t("MainTags.titlePonderadores");
        public isLoading = false;
        public headers = [
                    { text: 'Categoria', value: 'category'},
                    { text: 'Ponderacion Categoria', value: 'ponderacion'},
                    { text: 'Subcategoria', value: 'name'},
                    { text: 'Ponderacion Sub', value: 'dairy'},
                ]
        @Prop({
          type: Array,
          required: true,
        })
        readonly data!: Array<IBTable>;

      }
